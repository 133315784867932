import $get from 'lodash.get'
import $merge from 'lodash.merge'
import Vue from 'vue'

import { SET_LOADER as SET_CONTENT_LOADER } from '../contents/types'
import {
  RESET_INITIAL_STATE,
  SET_CURRENT_MODE,
  SET_CONTENT,
  SET_EPISODE_ID,
  SET_EPISODE_INDEX,
  SET_INSTALLED,
  SET_LOADER,
  SET_PERSISTANT_VISIBLE,
  SET_STATUS,
  SET_VOLUME,
} from './types'
import notifier from '~/utils/notifier'
import mixpanel from '~/utils/mixpanel'
import spoke, { $data } from '~/utils/spoke'
import { runExcentricFile } from '~/utils/voicer'

const excentrics = runExcentricFile('store', 'player')

const INITIAL_STATE = () => ({
  /**
   * @type {String}
   * audio : player will use the spoke media library
   * vimeo : player will use vimeo/player
   */
  currentMode: undefined,
  /**
   * @type {Boolean}
   * `true` when mediaplayer handlers are registered
   *  this operation is made in `plugins/spoke.js`
   * @default false
   */
  isInstalled: false,

  /**
   * @type {Boolean}
   * `true` while the audio file loading
   * @default false
   */
  isLoading: false,

  /**
   * @type {Boolean}
   * previously `isSmall`
   * `true` if the PersistantPlayer is visible
   * @default false
   */
  isPersistantVisible: false,

  /**
   * @type {Object}
   * currently played content abstract
   */
  content: {
    id: null,
    duration: 0,
    title: '',
    captions: {
      heading: {},
      thumbnail: {},
      cards: [{}],
    },
    content: '',
    heading: '',
    publishedAt: new Date(),
    documents: [],
    episodes: [],
    links: [],
    tags: [],
  },

  /**
   * @type {Number}
   * current selected episode index
   */
  episodeIndex: 0,

  /**
   * @type {String}
   * current selected episode id
   */
  episodeId: undefined,

  /**
   * @type {String}
   * @enum ['loading', 'ready', 'play', 'stop', 'error']
   * current player status (default is `stop`)
   * @default stop
   */
  status: 'stop',

  /**
   * @type {Number}
   */
  volume: spoke.mediaplayer.volume,
})

export const state = () => ({
  ...$merge(INITIAL_STATE(), excentrics.state),
})

export const actions = $merge(
  {
    /**
     * install all mediaplayer handlers,
     * shall be called whenever the player is mounted
     */
    installMediaplayer({ dispatch, commit, getters, state }) {
      if (state.isInstalled) {
        return
      }

      const notify = notifier(dispatch)

      commit(SET_INSTALLED, true)

      const isSameContent = (_content) =>
        _content && _content.id === state.content.id

      // Spoke dependency event handlers
      spoke.mediaplayer
        .on('loading', () => {
          commit(SET_STATUS, 'loading')
        })
        .on('load', () => {
          commit(SET_STATUS, 'ready')
        })
        .on('error', ({ data: error }) => {
          // NotAllowedError must not be catched
          // This error is a "false message" and must not be interpretated as it
          // by the user
          if ($get(error, 'name') !== 'NotAllowedError') {
            notify(
              'error',
              this.app.i18n.t('glob_media_load_error', {
                code: `${error || 1}`,
              }),
              error
            )
            commit(SET_STATUS, 'error')
            mixpanel.mediaError(spoke.mediaplayer.episode, error)
          }
        })
        .on('end', async () => {
          const index = state.episodeIndex
          const content = spoke.item(state.content)

          spoke.item(getters.currentEpisode).unload()

          if (content.hasNext(index)) {
            await content.next(index)
            dispatch('changePlayerEpisode', index + 1)
          } else {
            spoke.mediaplayer.stop()
            spoke.mediaplayer.defineEpisodeIndex(0)
            commit(SET_EPISODE_INDEX, 0)
            commit(SET_EPISODE_ID, spoke.mediaplayer.episode.id)
          }
        })
        .on('play', () => {
          commit(SET_STATUS, 'play')
          mixpanel.mediaPlay(spoke.mediaplayer.episode)
        })
        .on('pause', () => {
          commit(SET_STATUS, 'stop')

          mixpanel.mediaStop()
        })
        .on('stop', ({ content }) => {
          if (isSameContent(content)) {
            commit(SET_STATUS, 'stop')
            mixpanel.mediaStop()
          }
        })
        .on('unload', () => {
          commit(SET_STATUS, 'stop')
        })
        .on('timeupdate', () => {
          if (spoke.mediaplayer.isPlaying && state.status !== 'play') {
            commit(SET_STATUS, 'play')
          }
        })
    },

    /**
     * Called when we change content or launch first content
     * will redirect user to listen/content.id
     * if you don't want redirect user, use setPlayerContent
     * @param {object} content
     * @param {number} episodeIndex (default = undefined)
     * @param {boolean} noRedirect
     */
    changePlayerContent(
      { dispatch },
      { content, episodeIndex = undefined, noRedirect = false }
    ) {
      const { route } = this.app.context

      const requireRedirection = (contentId) => {
        if (
          ['listen-content', 'listen-content-comments'].includes(route.name) &&
          route.params.content === contentId
        ) {
          return false
        }
        return true
      }

      dispatch('setPlayerContent', { content, episodeIndex })

      if (
        noRedirect === false &&
        this.$voicer.getConfig('disablePageListen') === false &&
        requireRedirection(content.id) === true
      ) {
        Vue.nextTick(() => {
          this.$router.push({
            path: `/listen/${content.id}`,
          })
        })
      }
    },

    /**
     * set content in player
     * @param {object} content
     * @param {number} episodeIndex
     * @default {episodeIndex}
     * if episodeIndex is not a number (eg: undefined):
     * - if content remains the same: episodeIndex = current episode index
     * - if content differs: episodeIndex = 0
     */
    setPlayerContent({ commit, state }, { content, episodeIndex = undefined }) {
      if (Number.isInteger(episodeIndex) === false) {
        if (state.content.id !== content.id) {
          episodeIndex = 0
        } else {
          episodeIndex = state.episodeIndex
        }
      }

      // store has no content OR
      // the stored content !== commited content
      if (!state.content || state.content.id !== content.id) {
        commit(SET_CONTENT, { content, episodeIndex }) // player content
        commit(SET_EPISODE_INDEX, episodeIndex)

        const episode = content.episodes[episodeIndex]

        if (episode) {
          commit(SET_EPISODE_ID, episode.id)
        }
      }
      // store has a content AND
      // given episodeIndex !== episodeIndex
      else if (
        state.content.id === content.id &&
        state.episodeIndex !== episodeIndex
      ) {
        commit(SET_EPISODE_INDEX, episodeIndex)

        const episode = content.episodes[episodeIndex]

        if (episode) {
          commit(SET_EPISODE_ID, episode.id)
        }
      }
    },

    /**
     * Called whenever we change the episode
     * @param {Number} index index of episode
     */
    changePlayerEpisode({ commit, getters, state }, episodeIndex) {
      const episode = state.content.episodes[episodeIndex]

      // if the desired episode is different, we are calling the "unload"
      // method, which will properly destroy the media object
      if (getters.currentEpisode.id !== episode.id) {
        const spokeItem = spoke.item(getters.currentEpisode)

        spokeItem.unload()
      }

      commit(SET_STATUS, 'loading')
      commit(SET_EPISODE_ID, episode.id) // media playing
      commit(SET_EPISODE_INDEX, episodeIndex)

      // update the good URL segment (episode id)
      if (this.$voicer.getConfig('disablePageListen', false) === false) {
        if (window.history) {
          window.history.pushState(
            {},
            null,
            `/listen/${state.content.id}?episode=${episode.id}`
          )
        }
      }
    },

    /**
     * @param {ObjectID} contentId
     * @param {Number} episodeIndex [default=0]
     */
    loadPlayerContent(
      { commit, dispatch, state },
      { contentId, episodeId = undefined }
    ) {
      const { route } = this.app.context

      if (typeof episodeId === 'undefined') {
        episodeId = route.query.episode
      }

      if (!state.content || state.content.id !== contentId) {
        commit(SET_LOADER)
        commit(`contents/${SET_CONTENT_LOADER}`, true, { root: true })

        return new Promise((resolve, reject) => {
          spoke
            .collection('contents')
            .getOne(contentId)
            .lean()
            .on('success', (content) => {
              if (route.name === 'listen-content-comments') {
                dispatch('closePersistantPlayer')
              }

              let episodeIndex = 0
              content.episodes.forEach((ep, index) => {
                if (ep.id === episodeId) {
                  episodeIndex = index
                }
              })

              dispatch('changePlayerContent', { content, episodeIndex })

              commit(SET_LOADER, false)
              commit(`contents/${SET_CONTENT_LOADER}`, false, { root: true })
              resolve()

              // delayed action : get current content messages (see apropriate store)
              dispatch('messages/loadConversation', null, { root: true })
            })
            .on('error', (error) => {
              commit(SET_LOADER, false)
              commit(`contents/${SET_CONTENT_LOADER}`, false, { root: true })
              reject(error)
              this.$voicer.captureException(error)
            })
        })
      }

      return Promise.resolve()
    },

    resetState({ commit }) {
      commit(RESET_INITIAL_STATE)
    },

    /**
     * change the player mode (audio / vimeo)
     */
    changePlayerMode({ commit, dispatch }, mode) {
      commit(SET_CURRENT_MODE, mode)

      if (mode === 'vimeo') {
        dispatch('closePersistantPlayer')
      } else if (mode === 'audio') {
        dispatch('openPersistantPlayer')
      }
    },

    /**
     * close the persistant player
     */
    closePersistantPlayer({ commit }) {
      commit(SET_PERSISTANT_VISIBLE, false)
    },

    /**
     * open persistant player
     * triggered when a media file playing, is in pause or loading
     * and user is closing the listen view
     */
    openPersistantPlayer({ commit, state }) {
      if (this.app.context.route.name !== 'listen-content-comments') {
        commit(SET_PERSISTANT_VISIBLE, true)
      }
    },

    /**
     * force opening of persistant player
     */
    forceOpenPersistantPlayer({ commit, state }) {
      commit(SET_PERSISTANT_VISIBLE, true)
    },

    /**
     * close the player and stop the currently playing audio
     */
    closePlayer({ commit, dispatch }) {
      commit(SET_PERSISTANT_VISIBLE, false)
      dispatch('ctrlPlayer', 'stop')
    },

    /**
     * @todo
     * document this
     */
    async ctrlPlayer(context, controller) {
      let action
      let options

      if (typeof controller === 'string') {
        action = controller
        options = {}
      } else {
        if (controller.episodeIndex == null) {
          controller.episodeIndex = spoke.mediaplayer.episodeIndex
        }
        action = controller.action
        options = {
          content: controller.content || spoke.mediaplayer.content,
          episodeIndex: controller.episodeIndex,
        }
      }

      const control = spoke.item(
        $get(controller, 'content', spoke.mediaplayer.content)
      )

      if (options.episodeId) {
        context.commit(SET_EPISODE_ID, options.episodeId)
      }
      if (Number.isInteger(options.episodeIndex)) {
        context.commit(SET_EPISODE_INDEX, options.episodeIndex)
      }

      switch (action) {
        case 'next':
          // eslint-disable-next-line no-case-declarations
          const nextIndex = context.state.episodeIndex + 1

          if (control.$episode(nextIndex)) {
            control.next()
            await context.commit(SET_EPISODE_INDEX, nextIndex)
          }
          break
        case 'pause':
          await control.pause()
          break
        case 'play':
          {
            const episode = control.$episode(
              typeof options.episodeIndex !== 'undefined'
                ? options.episodeIndex
                : context.state.episodeIndex
            )

            const oldEpisode = spoke.item(context.getters.currentEpisode)

            if (context.getters.isPlaying && oldEpisode.id !== episode.id) {
              oldEpisode.stop()
            }

            if (episode) {
              await episode.play()
            }
          }
          break
        case 'prev':
          // eslint-disable-next-line no-case-declarations
          const prevIndex = context.state.episodeIndex - 1

          if (prevIndex < 0) {
            control.seek(0)

            if (control.isPlaying) {
              control.play()
            }
          } else {
            control.prev()
            await context.commit(SET_EPISODE_INDEX, prevIndex)
          }
          break
        case 'seek':
          control.seek(options.seek || 0)
          break
        case 'stop':
          control.stop()
          break
      }
    },

    /**
     * set the player volume
     */
    setVolume({ commit }, volume) {
      spoke.mediaplayer.setVolume(volume / 100)
      commit(SET_VOLUME, volume)
    },
  },
  excentrics.actions
)

export const mutations = $merge(
  {
    /**
     * @param {string} mode
     * change the player mode (audio, vimeo)
     */
    [SET_CURRENT_MODE](state, mode) {
      state.currentMode = mode
    },
    /**
     * @param {boolean} status
     * true if mediaplayer handlers are installed
     */
    [SET_INSTALLED](state, status = true) {
      state.isInstalled = status
    },

    /**
     * @public
     * @param {boolean} loading
     */
    [SET_LOADER](state, loading = true) {
      state.isLoading = loading
    },

    /**
     * @param {Object} content
     * @param {Number} episodeIndex
     * define the currently playing content
     */
    [SET_CONTENT](state, { content, episodeIndex }) {
      const item = spoke.item(content)

      spoke.mediaplayer.setContent(item, episodeIndex)
      state.content = item.toJSON()
    },

    /**
     * @param {Number} index episode index
     * @default 0
     * define the currently playing episode
     */
    [SET_EPISODE_INDEX](state, index = 0) {
      if (state.episodeIndex !== index) {
        spoke.mediaplayer.defineEpisodeIndex(index)
        state.episodeIndex = index
      }
    },

    /**
     * @param {Number} id episode id
     * @default 0
     * define the currently playing episode
     */
    [SET_EPISODE_ID](state, id = 0) {
      state.episodeId = id
    },

    /**
     * @param {boolean} status
     * @default true
     * set the mini player to visible or not
     */
    [SET_PERSISTANT_VISIBLE](state, status = true) {
      state.isPersistantVisible = status
    },

    /**
     * @param {string} value loading, play, stop, error
     * define the player state to `value`
     */
    [SET_STATUS](state, value) {
      if (state.status !== value) {
        state.status = value
      }
    },

    /**
     * @param {number} volume
     * set player volume and propagate
     */
    [SET_VOLUME](state, value) {
      state.volume = value
    },

    /**
     * reset vuex player store on initial state
     */
    [RESET_INITIAL_STATE](state) {
      Object.assign(state, INITIAL_STATE())
    },
  },
  excentrics.mutations
)

export const getters = $merge(
  {
    content(state) {
      return state.content
    },

    currentEpisode(state) {
      return state.content.episodes.find((ep) => ep.id === state.episodeId)
    },

    episodes(state) {
      return state.content.episodes
    },

    hasEpisodes(state) {
      return state.content.episodes.length > 1
    },

    hasNextEpisode(state) {
      return state.content.episodes[state.episodeIndex + 1]
    },

    isFirstEpisode(state) {
      return state.episodeIndex === 0
    },

    isLastEpisode(state) {
      return !state.content.episodes[state.episodeIndex + 1]
    },

    nextEpisode(state) {
      return state.content.episodes[state.episodeIndex + 1]
    },

    isLoaded(state) {
      return state.status !== 'loading'
    },

    isLoading(state) {
      return state.status === 'loading'
    },

    isPlaying(state) {
      return state.status === 'play'
    },

    isStopped(state) {
      return state.status === 'stop'
    },

    isError(state) {
      return state.status === 'error'
    },

    isPersistantVisible(state) {
      return state.isPersistantVisible
    },

    hasCommentsSystem(state) {
      return $data(state.content, 'conversations', []).some(
        (conversation) => conversation.type === 'comment'
      )
    },
    messager(state) {
      return $data(state.content, 'conversations', []).find(
        (conversation) => conversation.type === 'comment'
      )
    },
  },
  excentrics.getters
)
